.questionMarkWrapper {
  position: relative;
  cursor: pointer;
  background: inherit;
}

.informationCardWrapper {
  position: absolute;
  transform: translateX(-50%);
  z-index: 30;
}
