.banner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  width: 100%;
  height: 62.46px;
  background: rgba(0, 237, 123, 0.08);
  border-radius: 8px;
  padding-inline: 26px;
}

.banner_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  min-width: 105px;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
}

.banner_btn_text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #53e07b;
}

.banner_text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #00ed7b;
}
